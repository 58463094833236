<template>
    <div class="hover-green" @click="print(count)" >
        {{ showText ? $t("Management.Seals.SealPrint"):"" }}
        <feather-icon
        icon="AwardIcon"
        :size="iconSize"
        v-b-tooltip.hover.top="$t('Management.Seals.SealPrint')"
        />
    </div>
</template>
<script>
import { jsPDF } from "jspdf";
import { ProcessTypesEnum, SortTypesEnum } from '@/data/enums';
import autoTable from 'jspdf-autotable'
import { BFormInput, BButton, BModal, BFormGroup } from "bootstrap-vue";

export default {
    components:{BButton, BFormGroup, BModal, BFormInput},
    props:{
        process: {
            type: Object,
            required: true,
        },
        showText: {
            type: Boolean,
            required: true
        },
        iconSize: {
            type: String,
            required: true
        }
    },
    data: () => ({
        ProcessTypesEnum,
        SortTypesEnum,
        count: 1,
    }),

    methods: {
        async print(){
            const translationsRoot = "Management.Seals.Print";
            const doc = new jsPDF({unit: "mm", format: [98, 151], orientation: "landscape"} );
            const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            const center = pageWidth / 2;

            let german = true;
            let tableAreas = [];
            let areas = [];

            for (let index = 0; index < this.process.areas.length; index++) {
                const area = this.process.areas[index];                
                if(area.country.short !== "DE"){
                    german = false;
                }
                if(areas.findIndex(ar => ar === area.name) === -1){
                    if(area.country.short !== "DE" && area.country.name !== area.name){
                        areas.push([`${area.country.short} - ${area.name}`]);
                    } else{
                        areas.push([area.name]);
                    }
                }
            }
            
            for (let index = 0; index < areas.length && index < 12; index++) {
                const area = areas[index];

                if(this.process.areas[index + 2]){
                    tableAreas.push([`${areas[index]} / ${areas[index+1]} / ${areas[index+2]}`]);
                    index+=2;
                }
                else if(this.process.areas[index + 1]){
                    tableAreas.push([`${areas[index]} / ${areas[index+1]}`]);
                    index++;
                } else{
                    tableAreas.push([`${areas[index]}`]);
                }
            }

            let tableSorts = [];
            if(this.process.sorts.length <= 3) {
                for (let index = 0; index < this.process.sorts.length; index++) {
                    const sort = this.process.sorts[index];
                    tableSorts.push([sort.name])
                }
            } else {
                for (let index = 0; index < this.process.sorts.length && index < 12; index++) {
                    const sort = this.process.sorts[index];
                    
                    if(this.process.sorts[index + 1]){
                        tableSorts.push([`${this.process.sorts[index].name} / ${this.process.sorts[index + 1].name}`]);
                        index++;
                    } else {
                        tableSorts.push([sort.name])
                    }
                }   
            }
            
            


                doc.setFont("Times", "normal")
                doc.setFontSize(14);
                doc.text(this.$tc(`${translationsRoot}.Area`, 1), 10, 35);
                doc.text(this.$tc(`${translationsRoot}.Area`, 2), 10, 40);
                let sortHeight = 47 + tableAreas.length * 2;
                doc.text(this.$t(`${translationsRoot}.Sort`), 10, 50 + tableAreas.length);
                doc.text(this.$t(`${translationsRoot}.Bezug`), 10, 70);
                doc.text(this.$t(`${translationsRoot}.Harvest`),10, 77.5);

                doc.text(this.$t(`${translationsRoot}.Code`), 10, 85);
                // doc.text(this.$t(`${translationsRoot}.Number`), 10, 90);
 
                doc.setFont("Times", "bold")

                doc.setFontSize(16);
                // doc.text(`${this.from}`, center, 90);
                doc.text(`${this.process.harvestYear}` , 25, 77.5);
                doc.text(`${this.process.processId}` , 47, 70);
                doc.setFontSize(14);
                doc.text(this.$t(`${translationsRoot}.VA`), center, 18, "center");

                autoTable(doc, {
                    margin: { left: 30 },
                    startY: 31,
                    body: tableAreas,
                    theme:"plain",
                    columnStyles: { 0: { fontSize: 11 }, 1: { fontSize: 11 } },
                    cellWidth: 10,
                    styles: {
                        cellPadding: {top: 0, bottom: 0}
                    },
                });

                autoTable(doc, {
                    margin: { left: 30, right:53 },
                    startY: sortHeight-2,
                    body: tableSorts,
                    theme:"plain",
                    columnStyles: { 0: { fontSize: 11 }, 1: { fontSize: 11 } },
                    cellWidth: 10,
                    styles: {
                        cellPadding: {top: 0, bottom: 0}
                    },
                });




                doc.setFontSize(22);
                if(german){
                    doc.text(this.$tc(`${translationsRoot}.Header`, 1) + " " + this.$tc(`${translationsRoot}.Header`, 2), center, 10, "center");
                } else{
                    doc.text(this.$tc(`${translationsRoot}.Header`, 2), center, 10, "center");
                }


            window.open(doc.output('bloburl'), '_blank'); 
        },

    }
}
</script>
<stlye lang="scss">
    .from-to-container {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
    }
    .buttons-container{
        gap:1rem;
    }
</stlye>