<template>
<input
    ref="inputRef"
    type="text"
    :id="id"
    :disabled="disabled"
    :class="`${customClass} currencyInput`"
    :placeholder="'0,000'"
/>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';
import { watch } from '@vue/composition-api';

export default {
name: 'CurrencyInput',
props: {
    value: Number,
    options: Object,
    disabled: Boolean,
    id: String,
    customClass: String
},
setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options)
    
    watch(
      () => props.value,
      (value) => {
        setValue(value)
      }
    )

    return { inputRef }
  }
}
</script>
<style lang="scss">
.currencyInput {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    display: block;
    width: 100%;
    height: 2.714rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &.md{
        padding: 0.188rem 0.857rem;
        border-radius: 0.357rem;
    }
    &.sm{
        height: 2.142rem;
        font-size: 0.857rem;
        line-height: 1;
    }
    &.lg{
        height: 2.5rem;
        font-size: 0.857rem;
        line-height: 1;
    }
    &:focus, &:focus-visible{
        outline: auto;
        background-color: #fff;
        border-color: #425E5B;
        box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        border: 1px solid #d8d6de;
    }
}
</style>